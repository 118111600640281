
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {

  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-V2-U8-P60-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page60/E1/1.jpg' },
      ]
    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-V2-U8-P60-E2',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page43/E1/Key/answerKey.png',
    inputSize: 700,
    titleQuestion: [{ num: '2', title: 'Write about two people.' }],
    questionImage: [],
    questions: [
      {
        title:
          `
                <div style='color:rgb(82,197,235)'>Son would like some meat, a melon, and a cucumber..</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
      `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        // type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-V2-U8-P60-E3',
    audio: 'img/FriendsPlus/Page60/Audio/audio-e3-p60.mp3',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page60/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page60/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page60/Audio/tieude-e3-p60.mp3' },
        { url: 'img/FriendsPlus/Page60/E3/3.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page60/E3/4.jpg' },
      ]
    ]
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB5-V2-U8-P60-E4',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page60/E4/1.jpg' },
      ]
    ]
  },
}
export default json