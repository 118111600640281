import UI from '../../components/ExcerciseTypes/Design/UserInterface';



const json = {

  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-V2-U6-P47-E2',
    audio: '',
    video: '',
    recorder: true,
    component: UI,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page51/E1/1.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page50/E1/3.jpg' },
      ]
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-V2-U6-P47-E2',
    audio: '',
    video: '',
    recorder: true,
    component: UI,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page51/E1/2.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page50/E1/3.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page51/E1/3.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-V2-U6-P47-E2',
    audio: '',
    video: '',
    recorder: true,
    component: UI,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page51/E1/4.jpg' },
      ]
    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-V2-U6-P47-E2',
    audio: '',
    video: '',
    recorder: true,
    component: UI,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page51/E1/5.jpg' },
      ]
    ],
  },

}
export default json;
