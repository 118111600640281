import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-V2-U1-P12-E1',
    audio: 'img/FriendsPlus/Page12/Audio/audio-e1-p12.mp3',
    video: '',
    recorder: true,
    component: UI,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page12/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page12/Audio/tieude-e1-p12.mp3' },
        { url: 'img/FriendsPlus/Page12/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page12/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page12/Audio/hinh1-e1-p12.mp3' },
        { url: 'img/FriendsPlus/Page12/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page12/Audio/hinh2-e1-p12.mp3' },
        { url: 'img/FriendsPlus/Page12/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page12/Audio/hinh3-e1-p12.mp3' },
        { url: 'img/FriendsPlus/Page12/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page12/Audio/hinh4-e1-p12.mp3' },
        { url: 'img/FriendsPlus/Page12/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page12/Audio/hinh5-e1-p12.mp3' },
      ]
    ],
  },

  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-V2-U1-P12-E2',
    audio: '',
    video: '',
    // recorder: true,
    component: UI,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page12/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page12/E2/2.jpg' },
      ]
    ],
  },

  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-V2-U1-P12-E3',
    audio: 'img/FriendsPlus/Page12/Audio/audio-e3-p12.mp3',
    video: '',
    recorder: true,
    component: UI,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page12/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page12/Audio/tieude-e3-p12.mp3' },
        { url: 'img/FriendsPlus/Page12/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page12/E3/4.jpg' },
      ]
    ],
  },

  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB5-V2-U1-P12-E4',
    audio: '',
    video: '',
    component: D1,
    maxLength: 1,
    textAlign: 'center',
    recorder: false,
    exerciseKey: 'img/FriendsPlus/Page12/E4/Key/answerKey.png',
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page12/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/3.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page12/E4/4.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E4/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page12/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/7.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page12/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/9.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page12/E4/10.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page12/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/12.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page12/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page12/E4/14.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page12/E4/15.jpg' },
      ],
    ],
  },


}

export default json;