import UI from '../../components/ExcerciseTypes/Design/UserInterface';


const json = {

  1: { // Exercise num
    unit: 'Unit 7',
    id: 'SB5-V2-U7-P52-E1',
    audio: 'img/FriendsPlus/Page52/Audio/audio-e1-p52.mp3',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page52/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/tieude-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/3.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page52/E1/20.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page52/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh1-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh2-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh3-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh4-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/12.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page52/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/14.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh5-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/16.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh6-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/18.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh7-e1-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/19.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page52/E1/20.jpg' },
      ],


    ],
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'SB5-V2-U7-P52-E2',
    audio: 'img/FriendsPlus/Page52/Audio/audio-e2-p52.mp3',
    video: 'img/FriendsPlus/Page52/Videos/e2.p52.mp4',
    component: UI,
    recorder: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page52/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/tieude-e2-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page52/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh1-e2-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh2-e2-p52.mp3' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page52/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh3-e2-p52.mp3' },
        { url: 'img/FriendsPlus/Page52/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/hinh4-e2-p52.mp3' },
      ]
    ],
  },


}
export default json;
